<template>
<div class="CategorySection" style="background-color: #F5F5F5">
  <div class="timelineProgress"></div>
  <div class="timeLineHeadingContainer">
    <SubHeading class="subHeading" style="margin-bottom: 16px" title="ROADMAP" uniqueId="timeLineSubHeading"/>
    <Heading class="heading" style="margin-bottom: 20px" title="MORE TO COME" uniqueId="timeLineHeading" speed="30"/>
  </div>
  <div class="timeline">
    <div class="container left" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'phase1')" :class="{ 'animate-text-block' : phase1Visible }">
      <div class="content">
        <h3>MINT INITIAL FUTUREBOTS</h3>
        <ul>
          <li><p>Our FUTUREBOTs point out funny and thought-provoking predictions of the year 2050.</p></li>
          <li><p>Show the world that you care about the future by owning a FUTUREBOT.</p></li>
          <li><p>Mint one of 365 FUTUREBOTs and join our community for discussing trends and long-term developments. &#128522;</p></li>
          <li><p><b>#HELLOWORLD</b></p></li> 
        </ul>
      </div>
      <div class="roadmapPhasesLeft">START</div>
      <div class="roadmapPhasesImagesWrapperLeft">
        <img class="roadmapPhasesImages" src="../assets/roadmap/phase1.gif">
      </div>
    </div>
    <div class="container right" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'phase2')" :class="{ 'animate-text-block' : phase2Visible }">
      <div class="content">
        <h3>MINT NEW COLLECTIONS OF FUTUREBOTS</h3>
        <ul>
          <li><p>Based on user feedback, new collections of FUTUREBOTs are released.</p></li>
          <li><p>The community gets the opportunity to submit their own headlines and design suggestions.</p></li>
          <li><p>FUTUREBOT owners and idea contributors participate in a giveaway - 33% of each upcoming collection is airdropped for free. In addition, owners and idea contributors receive the opportunity to mint at a strongly decreased price.</p></li>
          <li><p>A maximum of 10 FUTUREBOTs collections - 10 years (365 days each) - will be created.</p></li>
          <li><p><b>#EXPAND</b></p></li>
        </ul>
      </div>
      <div class="roadmapPhasesRight">INCREASE</div>
      <div class="roadmapPhasesImagesWrapperRight">
        <img class="roadmapPhasesImages" src="../assets/roadmap/phase2.gif">
      </div>
    </div>
    <div class="container left" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'phase3')" :class="{ 'animate-text-block' : phase3Visible }">
      <div class="content">
        <h3>WORLD DISCUSSIONS</h3>
        <ul>
          <li><p>If you own a FUTUREBOT, you can join our events.</p></li>
          <li><p>You have the opportunity to see exciting presentations about the challenges of our world and possible solutions.</p></li>
          <li><p>Get involved in exciting discussions and contribute to a better world.</p></li>
          <li><p><b>#DOSOMETHING</b></p></li>
        </ul>
      </div>
      <div class="roadmapPhasesLeft">ENGAGE</div>
      <div class="roadmapPhasesImagesWrapperLeft">
        <img class="roadmapPhasesImages" src="../assets/roadmap/phase3.gif">
      </div>
    </div>
    <div class="container right" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'phase4')" :class="{ 'animate-text-block' : phase4Visible }">
      <div class="content">
        <h3>NFT WORLD NEWS DASHBOARD RELEASE</h3>
        <ul>
          <li><p>As the FUTUREBOTs already show, we focus on current trends and future research. Therefore, we will release our own, daily updated dashboard on the blockchain - the NFT World News Dashboard.</p></li>
          <li><p>The dashboard will cover the most important sociological, technological, economic, environmental and political topics of our world.</p></li>
          <li><p>We will show the world that independent journalism is possible. Get a sneak peak <PreviewDashboard /></p></li>
  
          <li><p><b>#THEDAYINONENFT</b></p></li>
        </ul>
        </div>
        <div class="roadmapPhasesRight">EXPAND</div>
        <div class="roadmapPhasesImagesWrapperRight">
          <img class="roadmapPhasesImages" src="../assets/roadmap/phase4.gif">
        </div>
    </div>
    <!--
    <div class="container left" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'phase5')" :class="{ 'animate-text-block' : phase5Visible }">
      <div class="content">
        <h3>RAFFELS</h3>
        <ul>
          <li><p>The first 10 editions of our daily NFT World News Dashboard will be airdropped to randomly selected FUTUREBOT owners.</p></li>
          <li><p>At a later date additional editions will be raffled to the owners of a FUTUREBOT.</p></li>
          <li><p><b>#WIN</b></p></li>
        </ul>
      </div>
      <div class="roadmapPhasesLeft">WIN</div>
      <div class="roadmapPhasesImagesWrapperLeft">
        <img class="roadmapPhasesImages" src="../assets/roadmap/phase5.gif">
      </div>
    </div>
    -->
    <div class="container left" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'phase6')" :class="{ 'animate-text-block' : phase6Visible }">
      <div class="content">
        <h3>INVESTMENTS</h3>
        <ul>
          <li><p>Because we want to make the world a better place, we will invest a share of our royalties in projects that are important for the future of our planet.</p></li>
          <li><p>Our first investment goes in education projects. Education is the first step to create the conditions for a better future.</p></li>
          <li><p>Further investments will be discussed with our community.</p></li>
          <li><p><b>#FUTURECARE</b></p></li>
        </ul>
      </div>
      <div class="roadmapPhasesLeft">IMPACT</div>
      <div class="roadmapPhasesImagesWrapperLeft">
        <img class="roadmapPhasesImages" src="../assets/roadmap/phase7.gif">
      </div>
    </div>
    <!--
    <div class="container left" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'phase8')" :class="{ 'animate-text-block' : phase7Visible }">
      <div class="content">
        <h3>INSIDES</h3>
        <ul>
          <li><p>Various NFT projects are evaluated with our data-driven analyzes.</p></li>
          <li><p>The owners of our NFTs receive information about promising other NFT projects in which they can invest and multiply their money at short notice.</p></li>
          <li><p><b>#BETTERTRAIDING</b></p></li>
        </ul>
      </div>
      <div class="roadmapPhasesLeft">DATA-DRIVEN</div>
      <div class="roadmapPhasesImagesWrapperLeft">
        <img class="roadmapPhasesImages" src="../assets/roadmap/phase8.gif">
      </div>
    </div>
    -->
    <div class="container right" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'phase8')" :class="{ 'animate-text-block' : phase7Visible }">
      <div class="content">
        <h3>MORE TO COME</h3>
        <ul>
          <li><p>Nothing is impossible - we will constantly interact with our community and listen to your feedback.</p></li>
          <li><p>Together we will shape the future!</p></li>
          <li><p><b>#COMMUNITYDRIVEN</b></p></li>
        </ul>
      </div>
      <div class="roadmapPhasesRight">EVOLVE</div>
      <div class="roadmapPhasesImagesWrapperRight">
        <img class="roadmapPhasesImages" src="../assets/roadmap/phase6.gif">
      </div>
    </div>
    </div>
    <div class="disclaimer">
      *DISCLAIMER: The contents of the roadmap are subject to constant change. The purchase decision of a Futurebots NFT may only be made based on the currently available services "as-is".
    </div>
  </div>
  

</template>

<script>
import SubHeading from '@/components/TextElements/SubHeading.vue'
import Heading from '@/components/TextElements/Heading.vue'
import PreviewDashboard from '@/components/PreviewDashboard.vue'
export default {
  name: 'TimeLine',
  props: [],
  components: {
    SubHeading,
    Heading,
    PreviewDashboard
  },
  data() {
    return {
      phase1Visible: false,
      phase2Visible: false,
      phase3Visible: false,
      phase4Visible: false,
      phase5Visible: false,
      phase6Visible: false,
      phase7Visible: false,
    }
  },
  methods: {
    visibilityChanged (isVisible, entry, element) {
      if (element == 'phase1') {
        this.phase1Visible = isVisible;
      }
      if (element == 'phase2') {
        this.phase2Visible = isVisible;
      }
      if (element == 'phase3') {
        this.phase3Visible = isVisible;
      }
      if (element == 'phase4') {
        this.phase4Visible = isVisible;
      }
      if (element == 'phase5') {
        this.phase5Visible = isVisible;
      }
      if (element == 'phase6') {
        this.phase6Visible = isVisible;
      }
      if (element == 'phase7') {
        this.phase7Visible = isVisible;
      }
    },
  },
  mounted() {}
}
</script>

<style scoped>

/**.timelineProgress{
    position: sticky;
    width: 3px;
    background-color: gray;
    left: calc(50% - 3px);
    z-index: 9;
    height: 30vh;
    top: 10px;
}*/

.disclaimer{
  font-size: 14px;
  margin: 30px;
  margin-top: 30px;
  font-style: italic;
  text-align: center;
}

.roadmapPhasesLeft{
  position: absolute;
  top: 50%;
  left: 110%;
  width: fit-content;
  font-weight: bold;
}

.roadmapPhasesRight{
  position: absolute;
  top: 50%;
  left: -30%;
  width: fit-content;
  font-weight: bold;
}

.roadmapPhasesImagesWrapperLeft{ 
  position: absolute;
  top: 50%;
  left: 140%;
  width: fit-content;
}

.roadmapPhasesImagesWrapperRight{ 
  position: absolute;
  top: 50%;
  left: -60%;
  width: fit-content;
}

.roadmapPhasesImages{
  width: 100px;
  margin-top: -50%;
}

p{
  margin-top: 10px;
  margin-bottom: 10px;
}

h3{
  margin-bottom: 20px;
}

.timeLineHeadingContainer{
  width: 100%;
  max-width: 1500px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 30px;
  padding-left: 30px;
}

.headerTimeLine{
  position: relative;
  max-width: 1500px;
  margin: 0 auto;
}

/* The actual timeline (the vertical ruler) */
.timeline {
  position: relative;
  max-width: 1500px;
  margin: 0 auto;
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
  content: '';
  position: absolute;
  width: 3px;
  background-color: lightgray;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}

/* Container around content */
.container {
  padding: 10px 40px 10px 30px;
  position: relative;
  background-color: inherit;
  width: 50%;
}

/* The circles on the timeline */
.container::after {
  content: '';
  position: absolute;
  width: 15px;
  height: 15px;
  right: -6px;
  background-color: lightgray;
  top: 50%;
  border-radius: 50%;
  z-index: 1;
}

/* Place the container to the left */
.left {
  left: 0;
}

/* Place the container to the right */
.right {
  left: 50%;
}

/* Add arrows to the left container (pointing right) */
.left::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 50%;
  width: 0;
  z-index: 1;
  right: 30px;
  border: medium solid white;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent white;
}

/* Add arrows to the right container (pointing left) */
.right::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 50%;
  width: 0;
  z-index: 1;
  left: 20px;
  border: medium solid white;
  border-width: 10px 10px 10px 0;
  border-color: transparent white transparent transparent;
}

/* Fix the circle for containers on the right side */
.right::after {
  left: -9px;
}

/* The actual content */
.content {
  padding: 20px 30px;
  background-color: white;
  position: relative;
  border-radius: 6px;
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 600px) {
  /* Place the timelime to the left */
  .timeline::after {
  left: 31px;
  }
  
  /* Full-width containers */
  .container {
  width: 100%;
  padding-left: 70px;
  padding-right: 25px;
  }
  
  /* Make sure that all arrows are pointing leftwards */
  .container::before {
  left: 60px;
  border: medium solid white;
  border-width: 10px 10px 10px 0;
  border-color: transparent white transparent transparent;
  }

  /* Make sure all circles are at the same spot */
  .left::after, .right::after {
  left: 22px;
  }
  
  /* Make all right containers behave like the left ones */
  .right {
  left: 0%;
  }
  .roadmapPhasesLeft{
    display: none;
  }
  .roadmapPhasesRight{
    display: none;
  }
  .roadmapPhasesImages{
    display: none;
  }
}
</style>
