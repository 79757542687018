<template>
  <lightbox
    scrollDisabled
    escDisabled
    moveDisabled
    :visible="visible"
    :imgs="imgs"
    :index="index"
    @hide="hide"
  >
  </lightbox>
  <span @click="show()">here.</span>
</template>


<script>
import lightbox from "vue-easy-lightbox";
export default {
  name: "Preview Dashboard",
  props: [],
  components: { lightbox },
  data() {
    return {
      imgs: require("../assets/roadmap/preview.png"), // Img Url , string or Array of string
      visible: false,
      index: 0, // default: 0
    };
  },
  methods: {
      show() {
        this.visible = true
      },
      hide() {
        this.visible = false
      }
    }
};
</script>

<style scoped>
span {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
  display: inline;
}
</style>
